import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import modalReducer from './modalSlice';
import organizationReducer from './organizationSlice';
import questionReducer from './questionSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    modals: modalReducer,
    organization: organizationReducer,
    questions: questionReducer,
  },
});

export default store;
