import React from 'react';
import classes from './styles.module.css';

const ResultPage = () => {
  return (
    <div className={classes.container}>
      <div>You have successfully completed your interview</div>
      <div>You can now close your browser</div>
    </div>
  );
};

export default ResultPage;
