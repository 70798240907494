import React from 'react';
import { Button, Table } from 'antd';
import styles from './styles.module.css';
import { useParams, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setSuccess } from '../../store/modalSlice';
import { getData } from './../../utils/axios/index';
import { urlMap } from '../../utils/urls';
import { setCurrentOrganizationInfo } from '../../store/organizationSlice';
import { Link } from 'react-router-dom';

const columns = [
  {
    title: 'S. No.',
    dataIndex: 'serialNumber',
    key: 'serialNumber',
  },
  {
    title: 'Opening Role',
    dataIndex: 'openingRole',
    key: 'openingRole',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const OrganizationDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.user.token);

  const [roles, setRoles] = React.useState([]);

  const { organizationId } = useParams();

  console.log(organizationId);

  const dataSource = roles.map((role, idx) => {
    return {
      key: idx + 1,
      serialNumber: idx + 1,
      openingRole: (
        <Link
          to={`/organization/dashboard/${organizationId}/interview/details/${role._id}`}
        >
          {role.name}
        </Link>
      ),
      status: role.status,
    };
  });

  React.useEffect(() => {
    const getOrganizationInterviewRoles = async () => {
      dispatch(setLoading(true));

      const data = await getData(
        urlMap.interviewRoles + '/' + organizationId,
        userToken
      );

      dispatch(setLoading(false));
      if (data.success === true) {
        dispatch(setSuccess(data.message));
        dispatch(setCurrentOrganizationInfo(data.organization));
        console.log(data.roles);
        setRoles(data.roles);
      }
    };

    getOrganizationInterviewRoles();
  }, [userToken]);

  const handleCreateOpening = () => {
    navigate(`/organization/dashboard/${organizationId}/interview/create`);
  };

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.heading}>
          Welcome in Hireverse, Let magic Happen
        </h2>
      </div>
      <div className={styles.tableContainer}>
        <Button
          type='primary'
          className={styles.button}
          onClick={handleCreateOpening}
        >
          Create Interview
        </Button>
        <Table
          dataSource={dataSource}
          columns={columns}
          className={styles.table}
        />
      </div>
    </div>
  );
};

export default OrganizationDashboard;
