import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentOrganization: null,
  organizations: [],
};

const organizationSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    setCurrentOrganizationInfo: (state, action) => {
      state.currentOrganization = action.payload.organization;
    },
  },
});

export const { setOrganizations, setCurrentOrganizationInfo } =
  organizationSlice.actions;

export default organizationSlice.reducer;
