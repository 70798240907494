import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Interview from './pages/Interview';
import Result from './pages/Result';
import GettingStarted from './pages/GettingStarted';
import SetUserDetails from './pages/SetUserDetails';
import CreateOrganization from './pages/CreateOrganization';
import ChooseOrganization from './pages/ChooseOrganization';
import OrganizationDashboard from './pages/OrganizationDashboard';
import { useDispatch } from 'react-redux';
import { setLoading, setSuccess } from './store/modalSlice';
import { getData } from './utils/axios';
import { urlMap } from './utils/urls';
import { loginSuccess } from './store/userSlice';
import { setOrganizations } from './store/organizationSlice';
import CreateInterview from './pages/CreateInterview';
import Interviews from './pages/Interviews';
import InterviewEdit from './pages/InterviewEdit';
import InterviewResult from './pages/InterviewResult';

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    const getUserData = async () => {
      dispatch(setLoading(true));

      const data = await getData(urlMap.adminDetails, token);

      console.log(data);

      if (data.success === true) {
        dispatch(setSuccess(data.message));
        dispatch(
          loginSuccess({
            token: token,
            user: data.user,
          })
        );
        dispatch(setOrganizations(data.organizations));
      } else {
        localStorage.removeItem('token');
        navigate('/');
      }

      dispatch(setLoading(false));
    };

    getUserData();
  }, []);

  return (
    <Routes>
      <Route exact path='/interview/:interviewId/start' element={<Home />} />
      <Route exact path='/' element={<GettingStarted />} />
      <Route exact path='/interview/:interviewId' element={<Interview />} />
      <Route exact path='/result' element={<Result />} />
      <Route
        exact
        path='/verify/admin/details/set/:token'
        element={<SetUserDetails />}
      />
      <Route
        exact
        path='organization/create'
        element={<CreateOrganization />}
      />
      <Route
        exact
        path='/organization/choose'
        element={<ChooseOrganization />}
      />
      <Route
        exact
        path='/organization/dashboard/:organizationId'
        element={<OrganizationDashboard />}
      />
      <Route
        exact
        path='/organization/dashboard/:organizationId/interview/create'
        element={<CreateInterview />}
      />
      <Route
        exact
        path='/organization/dashboard/:organizationId/interview/details/:roleId'
        element={<Interviews />}
      />
      <Route
        exact
        path='/organization/dashboard/:organizationId/interview/edit/:interviewId'
        element={<InterviewEdit />}
      />
      <Route
        exact
        path='/organization/dashboard/:organizationId/inteview/result/:interviewId'
        element={<InterviewResult />}
      />

      <Route path='*' element={<div>Not found</div>} />
    </Routes>
  );
};

export default App;
