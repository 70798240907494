import React from 'react';
import { Button, Form, Input } from 'antd';
import styles from './styles.module.css';
import { urlMap } from '../../utils/urls';
import { postData } from './../../utils/axios/index';
import { useDispatch, useSelector } from 'react-redux';
import { setError, setLoading, setSuccess } from '../../store/modalSlice';
import { useNavigate } from 'react-router';

const CenteredForm = () => {
  const dispatch = useDispatch();
  const naviage = useNavigate();
  const [form] = Form.useForm();
  const userToken = useSelector((state) => state.user.token);

  const onFinish = async (values) => {
    console.log('Form values:', values);

    dispatch(setLoading(true));
    const data = await postData(
      urlMap.createOrganization,
      {
        name: values.orgName,
      },
      userToken
    );
    console.log(data);
    dispatch(setLoading(false));

    if (data.success === true) {
      form.resetFields();
      dispatch(setSuccess(data.message));
      naviage(`/organization/dashboard/${data.Organization._id}`);
    } else {
      dispatch(setError(data.message));
    }
  };

  return (
    <div className={styles.centeredForm}>
      <Form className={styles.form} onFinish={onFinish}>
        <h2 className={styles.heading}>Create an Orgnization</h2>
        <Form.Item
          label='Organization name'
          name='orgName'
          rules={[
            { required: true, message: 'Please enter organization name' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item className={styles.btnContainer}>
          <Button type='primary' htmlType='submit'>
            Create
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CenteredForm;
