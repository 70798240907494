import React from 'react';
import { Form, Input, Button } from 'antd';
import classes from './styles.module.css';
import { postData } from '../../utils/axios/index';
import { urlMap } from '../../utils/urls';
import { useDispatch, useSelector } from 'react-redux';
import { setError, setSuccess, setLoading } from '../../store/modalSlice';
import { loginSuccess } from '../../store/userSlice';
import { setOrganizations } from '../../store/organizationSlice';
import { useNavigate } from 'react-router';

const GettingStarted = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoginForm, setIsLoginForm] = React.useState(false);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  console.log(user);

  const loginHandler = async (values) => {
    dispatch(setLoading(true));

    const data = await postData(urlMap.login, {
      email: values.email,
      password: values.password,
    });

    console.log(data);

    dispatch(setLoading(false));

    if (data.success === true) {
      dispatch(setSuccess(data.message));
      dispatch(
        loginSuccess({
          token: data.token,
          user: data.user,
        })
      );

      localStorage.setItem('token', data.token);

      dispatch(setOrganizations(data.organizations));

      if (data.organizations.length === 0) {
        navigate('/organization/create');
      } else if (data.organizations.length === 1) {
        navigate(
          `/organization/dashboard/${data.organizations[0].organization._id}`
        );
      } else {
        navigate(`/organization/choose`);
      }
    } else {
      form.resetFields();
      dispatch(setError(data.message));
    }
  };

  const onFinish = async (values) => {
    console.log(values);

    if (isLoginForm === true) {
      await loginHandler(values);
      return;
    }

    dispatch(setLoading(true));

    const data = await postData(urlMap.gettingStarted, values);

    if (data.userExists) {
      setIsLoginForm(true);
    } else {
      form.resetFields();
    }

    dispatch(setLoading(false));

    if (data.success === false) {
      form.resetFields();
      dispatch(setError(data.message));
    } else {
      dispatch(setSuccess(data.message));
    }
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.heading}>
        <p>{isLoginForm === false && 'Sign up /'} Log in to your account</p>
      </div>
      <Form
        name='centered_form'
        onFinish={onFinish}
        style={{ width: 300 }}
        form={form}
      >
        <Form.Item
          name='email'
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please input a valid email!' },
          ]}
        >
          <Input placeholder='Email' />
        </Form.Item>
        {isLoginForm && (
          <Form.Item
            name='password'
            rules={[
              { required: true, message: 'Please input your password!' },
              {
                min: 3,
                message: 'Password must be at least 3 characters long!',
              },
            ]}
          >
            <Input.Password placeholder='Password' />
          </Form.Item>
        )}

        <Form.Item>
          <Button type='primary' htmlType='submit' block>
            {isLoginForm ? 'Login' : 'Get Started'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GettingStarted;
