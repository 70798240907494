import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { message, Modal } from 'antd';
import { clearMessage } from '../../store/modalSlice';
import LoadingIndicator from '../LoadingIndicator';

const MessageDisplay = () => {
  const dispatch = useDispatch();
  const { errorMessage, successMessage, infoMessage } = useSelector(
    (state) => state.modals
  );

  const handleClose = () => {
    dispatch(clearMessage());
  };

  if (errorMessage) {
    message.error(errorMessage, 3, handleClose);
  }

  if (successMessage) {
    message.success(successMessage, 3, handleClose);
  }

  if (infoMessage) {
    Modal.info({
      title: 'Information',
      content: infoMessage,
      onOk: handleClose,
    });
  }

  return (
    <div>
      <LoadingIndicator />
    </div>
  );
};

export default MessageDisplay;
