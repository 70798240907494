import React from 'react';
import {
  Form,
  Upload,
  Input,
  Button,
  Select,
  DatePicker,
  Modal,
  message,
} from 'antd';
import styles from './styles.module.css';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setError, setLoading, setSuccess } from '../../store/modalSlice';
import { getData, postData, postFormData } from '../../utils/axios';
import { urlMap } from '../../utils/urls';
import { setCurrentOrganizationInfo } from '../../store/organizationSlice';
import { useSearchParams } from 'react-router-dom';

const { Option } = Select;

const CreateInterview = () => {
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.user.token);
  const [searchParams, setSearchParams] = useSearchParams();

  const [roles, setRoles] = React.useState([]);

  const getOrganizationInterviewRoles = async () => {
    dispatch(setLoading(true));

    const data = await getData(
      urlMap.interviewRoles + '/' + organizationId,
      userToken
    );

    dispatch(setLoading(false));
    if (data.success === true) {
      dispatch(setCurrentOrganizationInfo(data.organization));
      setRoles(data.roles);
    }
  };

  React.useEffect(() => {
    getOrganizationInterviewRoles();
  }, [userToken]);

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleCreateRole = async (values) => {
    console.log('Role values', values);

    const roleData = {
      name: values.name,
      skills: values.skills.split(',').map((skill) => skill.trim()),
      minExperience: values['experience.min'],
      maxExperience: values['experience.max'],
      jobDescription: values.jobDescription,
      organizationId: organizationId,
    };

    dispatch(setLoading(true));

    const data = await postData(urlMap.createRole, roleData, userToken);

    console.log(data);

    dispatch(setLoading(false));

    if (data.success === true) {
      dispatch(setSuccess(data.message));
      getOrganizationInterviewRoles();
      setIsModalVisible(false);
    } else {
      dispatch(setError(data.message));
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    console.log('Form values:', values);

    const formData = new FormData();

    formData.append('resume', values.resume.file.originFileObj);
    formData.append('name', values.interviewName);
    formData.append('role', values.openingRole);
    formData.append('interviewType', values.interviewType);
    formData.append('validTill', values.validTill);
    formData.append('organizationId', organizationId);

    dispatch(setLoading(true));
    const data = await postFormData(
      urlMap.createInterview,
      formData,
      userToken
    );

    console.log(data);

    dispatch(setLoading(false));

    if (data.success === true) {
      dispatch(setSuccess(data.message));
      navigate(
        `/organization/dashboard/${organizationId}/interview/edit/${data.packet._id}`
      );
    }
  };

  const onRoleCreate = () => {
    showModal();
  };

  const uploadProps = {
    beforeUpload: (file) => {
      const isPdf = file.type === 'application/pdf';
      if (!isPdf) {
        message.error('You can only upload PDF files!');
      }
      return isPdf ? true : Upload.LIST_IGNORE;
    },
  };

  console.log(organizationId);

  return (
    <div className={styles.container}>
      <Form
        name='centered-form'
        initialValues={{ interviewType: 'structured' }}
        onFinish={onFinish}
        className={styles.formContainer}
      >
        <h1 className={styles.heading}>
          Welcome to Hireverse, Let Magic Happen
        </h1>

        <Form.Item
          name='resume'
          label='Resume'
          rules={[{ required: true, message: 'Please upload your resume!' }]}
        >
          <Upload {...uploadProps}>
            <Button>Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name='interviewName'
          label='Interview Name'
          rules={[
            { required: true, message: 'Please provide the interview name!' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='openingRole'
          label='Opening Role'
          rules={[
            {
              required: true,
              message: 'Please select or create an opening role!',
            },
          ]}
        >
          <Select defaultValue={searchParams.get('role')}>
            {roles.map((role) => {
              return (
                <Option key={role._id} value={role._id.toString()}>
                  {role.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Button
          type='link'
          onClick={onRoleCreate}
          style={{ marginBottom: '30px' }}
        >
          Create Role
        </Button>

        <Form.Item
          name='interviewType'
          label='Interview Type'
          rules={[
            { required: true, message: 'Please select the interview type!' },
          ]}
        >
          <Select>
            <Option value='structured'>Structured</Option>
            <Option value='unstructured'>Unstructured (Coming Soon)</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='validTill'
          label='Valid Till'
          rules={[
            {
              required: true,
              message: 'Please select the validity date and time!',
            },
          ]}
        >
          <DatePicker showTime />
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit' style={{ width: '100%' }}>
            Create interview with AI
          </Button>
        </Form.Item>
      </Form>
      <Modal
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        footer={null}
        className={styles.modalContainer}
      >
        <Form onFinish={handleCreateRole} className={styles.roleForm}>
          <h2 className={styles.modalHeading}>Create Opening Role</h2>
          <Form.Item
            name='name'
            label='Name'
            rules={[{ required: true, message: 'Please enter the role name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name='skills'
            label='Skills'
            rules={[
              {
                required: true,
                message: 'Please enter skills separated by commas!',
              },
            ]}
          >
            <Input placeholder='Enter skills separated by commas' />
          </Form.Item>

          <Form.Item label='Experience' style={{ marginBottom: 0 }}>
            <Form.Item
              name='experience.min'
              rules={[
                {
                  required: true,
                  message: 'Please enter the minimum experience!',
                },
              ]}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 8px)',
                marginRight: '8px',
              }}
            >
              <Input type='number' placeholder='Min' />
            </Form.Item>
            <Form.Item
              name='experience.max'
              rules={[
                {
                  required: true,
                  message: 'Please enter the maximum experience!',
                },
              ]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input type='number' placeholder='Max' />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name='jobDescription'
            label='Job Description'
            rules={[
              { required: true, message: 'Please enter the job description!' },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item className={styles.roleButtonContainer}>
            <Button type='primary' htmlType='submit'>
              Create Role
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateInterview;
