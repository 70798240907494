import React from 'react';
import { useNavigate, useParams } from 'react-router';
import styles from './styles.module.css';
import { Form, Input, Button, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { useDispatch } from 'react-redux';
import { postData } from '../../utils/axios';
import { urlMap } from '../../utils/urls';
import { setError, setLoading, setSuccess } from '../../store/modalSlice';
import { loginSuccess } from '../../store/userSlice';
import { setOrganizations } from '../../store/organizationSlice';

const SetUserDetails = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { token } = useParams();

  const onFinish = async (values) => {
    console.log(values);

    dispatch(setLoading(true));

    const userData = {
      token,
      name: values.name,
      password: values.password,
      mobileNo: values.phone.countryCode + ' ' + values.phone.number,
    };

    const data = await postData(urlMap.adminDetails, userData);

    dispatch(setLoading(false));

    if (data.success === true) {
      dispatch(
        loginSuccess({
          token: data.token,
          user: data.user,
        })
      );

      localStorage.setItem('token', data.token);

      dispatch(setOrganizations(data.organizations));
      dispatch(setSuccess(data.message));
      navigate('/organization/create');
    } else {
      form.resetFields();
      dispatch(setError(data.message));
    }
  };

  const validatePassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Passwords do not match'));
    },
  });

  return (
    <div className={styles.centerContainer}>
      <div className={styles.centeredForm}>
        <h2 className={styles.heading}>
          Enter your details to create an account
        </h2>
        <Form onFinish={onFinish}>
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Phone Number'
            name='phone'
            rules={[
              { required: true, message: 'Please enter your phone number' },
            ]}
          >
            <Input.Group compact>
              <Form.Item
                name={['phone', 'countryCode']}
                noStyle
                rules={[
                  { required: true, message: 'Please select country code' },
                ]}
              >
                <Select style={{ width: '30%' }} defaultValue='+1'>
                  <Option value='+1'>+1</Option>
                  <Option value='+91'>+91</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={['phone', 'number']}
                noStyle
                rules={[
                  { required: true, message: 'Please enter phone number' },
                ]}
              >
                <Input style={{ width: '70%' }} placeholder='123 456 7890' />
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item
            label='Password'
            name='password'
            rules={[
              { required: true, message: 'Please enter a password' },
              {
                min: 3,
                message: 'Password must be at least 3 characters long',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label='Repeat Password'
            name='repeatPassword'
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please repeat your password' },
              validatePassword,
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item className={styles.submitButton}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SetUserDetails;
