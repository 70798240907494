import axios from 'axios';
import { urlMap } from '../urls';

export const getAudioProps = (
  status,
  audioSrc,
  setAudioSrc,
  question,
  packetId,
  isLast
) => {
  const audioProps = {
    audioType: 'audio/mp3',
    status,
    audioSrc,
    timeslice: 1000,
    startCallback: (e) => {
      console.log('succ start', e);
    },
    pauseCallback: (e) => {
      console.log('succ pause', e);
    },
    stopCallback: async (e) => {
      const audioUrl = window.URL.createObjectURL(e);

      setAudioSrc(audioUrl);
      try {
        const transcribedText = await sendAudioTranscriptionRequest(
          audioUrl,
          question,
          packetId
        );
        console.log(transcribedText);
      } catch (err) {
        console.log(err);
      }
      console.log('succ stop', e);
    },
    onRecordCallback: (e) => {
      console.log('recording', e);
    },
    errorCallback: (err) => {
      console.log('error', err);
    },
  };

  return audioProps;
};

export const sendAudioTranscriptionRequest = async (
  audioSrc,
  question,
  packedId
) => {
  const response = await fetch(audioSrc);
  const audioBlob = await response.blob();
  const audioFile = new File([audioBlob], 'recorded_audio.mp3', {
    type: 'audio/mp3',
  });

  console.log(audioFile);

  const formData = new FormData();
  formData.append('audio', audioFile);
  formData.append('question', question);
  formData.append('packetId', packedId);

  try {
    const response = await axios.post(urlMap.evaluateSolution, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(response);
  } catch (error) {
    throw new Error(error.message);
  }
};
