import React from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import classes from './styles.module.css';

const LoadingIndicator = () => {
  const loading = useSelector((state) => state.modals.loading);

  if (loading) {
    return (
      <div className={classes.loadingIndicatorContainer}>
        <Spin size='large' />
      </div>
    );
  }

  return null;
};

export default LoadingIndicator;
