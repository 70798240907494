import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modals',
  initialState: {
    errorMessage: null,
    successMessage: null,
    infoMessage: null,
    loading: false,
  },
  reducers: {
    setError: (state, action) => {
      state.errorMessage = action.payload;
    },
    setSuccess: (state, action) => {
      state.successMessage = action.payload;
    },
    setInfo: (state, action) => {
      state.infoMessage = action.payload;
    },
    clearMessage: (state) => {
      state.errorMessage = null;
      state.successMessage = null;
      state.infoMessage = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

const { setError, setSuccess, setInfo, clearMessage, setLoading } =
  modalSlice.actions;

export { setError, setSuccess, setInfo, clearMessage, setLoading };

export default modalSlice.reducer;
