//export const BACKEND_URL = 'http://localhost:5000';
export const BACKEND_URL = 'https://hireverse-backend.onrender.com';

export const urlMap = {
  evaluate: `${BACKEND_URL}/api/transcribe`,
  result: `${BACKEND_URL}/api/result`,
  login: `${BACKEND_URL}/api/v1/user/auth/login`,
  gettingStarted: `${BACKEND_URL}/api/v1/user/auth/started`,
  adminDetails: `${BACKEND_URL}/api/v1/user/details`,
  checkOrgHandleAvailable: `${BACKEND_URL}/api/v1/organization/handle/available/check`,
  createOrganization: `${BACKEND_URL}/api/v1/organization/create`,
  interviewRoles: `${BACKEND_URL}/api/v1/organization/roles`,
  getInterviews: `${BACKEND_URL}/api/v1/organization/interviews`,
  getInterviewResult: `${BACKEND_URL}/api/v1/organization/interview/result`,
  createRole: `${BACKEND_URL}/api/v1/interview/role/create`,
  createInterview: `${BACKEND_URL}/api/v1/packet/create`,
  getInterview: `${BACKEND_URL}/api/v1/packet`,
  editInterviewQuestion: `${BACKEND_URL}/api/v1/packet/role/questions/edit`,
  startInterview: `${BACKEND_URL}/api/v1/packet/start`,
  evaluateSolution: `${BACKEND_URL}/api/v1/packet/evaluate`,
  endInterview: `${BACKEND_URL}/api/v1/packet/end`,
};
