import React from 'react';
import { Card } from 'antd';
import styles from './styles.module.css'; // Import the CSS module
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const AntdContainer = () => {
  const organizations = useSelector(
    (state) => state.organization.organizations
  );

  return (
    <div className={styles.container}>
      <Card title='Choose the Organization' className={styles.outerCard}>
        {organizations.map((organization) => {
          return (
            <Card
              type='inner'
              title={organization.organization.name}
              extra={
                <Link
                  to={`/organization/dashboard/${organization.organization._id}`}
                >
                  Enter
                </Link>
              }
              className={styles.orgCard}
              key={organization.organization._id}
            >
              Role: {organization.role}
            </Card>
          );
        })}
      </Card>
    </div>
  );
};

export default AntdContainer;
