import axios from 'axios';

export const requestWithToken = async (method, url, data = {}, token = '') => {
  try {
    const response = await axios({
      method,
      url: url,
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getData = async (url, token = '') => {
  return requestWithToken('get', url, null, token);
};

export const postData = async (url, data = {}, token = '') => {
  return requestWithToken('post', url, data, token);
};

export const putData = async (url, data = {}, token = '') => {
  return requestWithToken('put', url, data, token);
};

export const deleteData = async (url, token = '') => {
  return requestWithToken('delete', url, null, token);
};

export const postFormData = async (url, formData, token = '') => {
  try {
    const response = await axios({
      method: 'post',
      url: url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data', // Use multipart/form-data for form data
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
