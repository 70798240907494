import React, { useEffect, useState } from 'react';
import { Typography, Tag, Table, Button, Modal } from 'antd';
import styles from './styles.module.css';
import { useNavigate, useParams } from 'react-router';
import { getData } from './../../utils/axios/index';
import { urlMap } from '../../utils/urls';
import { useDispatch, useSelector } from 'react-redux';
import { setError, setLoading, setSuccess } from '../../store/modalSlice';
import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

const JobDescription = ({ description }) => {
  const [showFullText, setShowFullText] = useState(false);

  const onCancel = () => {
    setShowFullText(false);
  };

  const toggleTextVisibility = () => {
    setShowFullText(!showFullText);
  };

  const displayedText = description.split(' ').slice(0, 10).join(' ');

  const buttonText = ' Read more';

  return (
    <div className={styles.jobDescription}>
      <span>
        <strong>Job Description: </strong>
      </span>
      <span>{displayedText}</span>
      {description.split(' ').length > 10 && (
        <span className={styles.readMore} onClick={toggleTextVisibility}>
          {buttonText}
        </span>
      )}

      <Modal
        title='Job Description'
        open={showFullText}
        footer={null}
        onCancel={onCancel}
      >
        <p>{description}</p>
      </Modal>
    </div>
  );
};

const App = () => {
  const [role, setRole] = React.useState(null);
  const [interviews, setInterviews] = React.useState([]);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { organizationId, roleId } = useParams();

  console.log(organizationId, roleId);

  function sortObjectsByTotalScore(objects) {
    const sortedObjects = objects.slice().sort((a, b) => {
      const totalScoreA =
        a.totalScore !== null ? a.totalScore : Number.NEGATIVE_INFINITY;
      const totalScoreB =
        b.totalScore !== null ? b.totalScore : Number.NEGATIVE_INFINITY;
      return totalScoreB - totalScoreA;
    });
    return sortedObjects;
  }

  const sortedInterviews = sortObjectsByTotalScore(interviews);

  const data = sortedInterviews.map((interview, idx) => {
    return {
      key: idx + 1,
      interviewName: (
        <Link
          to={`/organization/dashboard/${organizationId}/inteview/result/${interview._id}`}
        >
          {interview.name}
        </Link>
      ),
      created: interview.createdAt,
      attemptDate:
        interview.status === 'unattempted'
          ? 'Not yet attempted'
          : interview.attemptedAt,
      result: interview.totalScore === undefined ? 'NA' : interview.totalScore,
      url: `${window.location.origin}/interview/${interview._id}/start`,
    };
  });

  const handleCopyUrl = (url) => {
    const textArea = document.createElement('textarea');
    textArea.value = url;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    console.log('URL copied:', url);
  };

  React.useEffect(() => {
    if (token === null) return;
    dispatch(setLoading(true));
    getData(
      urlMap.getInterviews + '/' + organizationId + '/' + roleId,
      token
    ).then((data) => {
      dispatch(setLoading(false));
      console.log(data);

      if (data.success === true) {
        dispatch(setSuccess(data.message));
        setRole(data.role);
        setInterviews(data.interviews);
      } else {
        dispatch(setError(data.message));
      }
    });
  }, [token]);

  const handleCreateOpening = () => {
    navigate(
      `/organization/dashboard/${organizationId}/interview/create?role=${roleId}`
    );
  };

  return (
    role && (
      <div className={styles.container}>
        <Title className={styles.mainHeading}>{role?.name}</Title>
        <div className={styles.skills}>
          <Text>
            <strong>skills: </strong>
          </Text>
          {role.skills.map((skill) => {
            return <Tag>{skill}</Tag>;
          })}
        </div>
        <Text className={styles.experience}>
          <strong>Experience: </strong>
          {`${role.minExperience}-${role.maxExperience} years`}
        </Text>
        <JobDescription description={role.jobDescription} />
        <div className={styles.buttonContainer}>
          <Button
            type='primary'
            className={styles.button}
            onClick={handleCreateOpening}
          >
            Create Interview
          </Button>
        </div>
        <Table dataSource={data} className={styles.table}>
          <Table.Column title='S. no.' dataIndex='key' key='key' />
          <Table.Column
            title='Interview Name'
            dataIndex='interviewName'
            key='interviewName'
          />
          <Table.Column title='Created' dataIndex='created' key='created' />
          <Table.Column
            title='Attempt Date'
            dataIndex='attemptDate'
            key='attemptDate'
          />
          <Table.Column title='Score' dataIndex='result' key='result' />
          <Table.Column
            title='Copy URL'
            key='copy'
            render={(record) => (
              <Button
                className={styles.copyButton}
                onClick={() => handleCopyUrl(record.url)}
              >
                Copy
              </Button>
            )}
          />
        </Table>
      </div>
    )
  );
};

export default App;
