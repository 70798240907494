import React, { useState } from 'react';
import { Button, Form, Input, Row, Col } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import styles from './styles.module.css';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setError, setLoading, setSuccess } from '../../store/modalSlice';
import { getData, postData } from '../../utils/axios';
import { urlMap } from '../../utils/urls';

const InterviewEdit = () => {
  const { organizationId, interviewId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.user.token);

  console.log(organizationId, interviewId);

  const [form] = Form.useForm();
  const [questions, setQuestions] = useState([]);
  const [interviewName, setInterviewName] = useState('');
  const interviewUrl = `${window.location.origin}/interview/${interviewId}/start`;
  const [copied, setCopied] = useState(false);

  const handleSave = async (values) => {
    console.log('Form values:', values);

    dispatch(setLoading(true));

    const ques = [...questions];

    for (let i = 0; i < ques.length; i++) {
      if (values[i.toString()] !== undefined) {
        ques[i] = values[i.toString()];
      }
    }

    const data = await postData(
      urlMap.editInterviewQuestion,
      { packetId: interviewId, questions: ques },
      userToken
    );

    dispatch(setLoading(false));

    if (data.success === true) {
      dispatch(setSuccess(data.message));
      setQuestions(data.packet.role.questions);
    } else {
      dispatch(setError(data.message));
    }
  };

  const handleCopy = () => {
    setCopied(true);
  };

  const getInterviewQuestions = async () => {
    dispatch(setLoading(true));

    const data = await getData(
      urlMap.getInterview + '/' + interviewId,
      userToken
    );

    console.log(data);

    dispatch(setLoading(false));
    if (data.success === true) {
      setQuestions(data.packet.role.questions);
      setInterviewName(data.packet.name);
    }
  };

  React.useEffect(() => {
    getInterviewQuestions();
  }, [userToken]);

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>{interviewName}</h1>

      <Form form={form} layout='vertical' onFinish={handleSave}>
        {questions.map((question, index) => (
          <Form.Item key={index} label={`Question ${index + 1}`} name={index}>
            <Input.TextArea defaultValue={question} />
          </Form.Item>
        ))}
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Save
          </Button>
        </Form.Item>
      </Form>

      <Row className={styles.urlRow}>
        <Col span={18}>
          <Input className={styles.urlInput} readOnly value={interviewUrl} />
        </Col>
        <Col span={6}>
          <CopyToClipboard text={interviewUrl} onCopy={handleCopy}>
            <Button className={styles.copyButton} icon={<CopyOutlined />}>
              {copied ? 'Copied!' : 'Copy URL'}
            </Button>
          </CopyToClipboard>
        </Col>
      </Row>

      <div className={styles.dashboardButton}>
        <Link to={`/organization/dashboard/${organizationId}`}>
          <Button type='primary' size='large'>
            Go To Dashboard
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default InterviewEdit;
