import React from 'react';
import { useParams } from 'react-router';
import { getData, postData } from './../../utils/axios/index';
import { urlMap } from '../../utils/urls';
import { useDispatch, useSelector } from 'react-redux';
import { setError, setLoading, setSuccess } from '../../store/modalSlice';
import { Card, Collapse, Button, Row, Col, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import axios from 'axios';

const { Panel } = Collapse;

const InterviewResult = () => {
  const [interview, setInterview] = React.useState(null);
  const [copySuccess, setCopySuccess] = React.useState(false);

  const token = useSelector((state) => state.user.token);
  const { organizationId, interviewId } = useParams();
  const dispatch = useDispatch();
  console.log(organizationId, interviewId);

  const handleCopyClick = (interviewUrl) => {
    navigator.clipboard.writeText(interviewUrl).then(
      () => {
        message.success('URL copied to clipboard');
        setCopySuccess(true);

        setTimeout(() => {
          setCopySuccess(false);
        }, 2000);
      },
      () => {
        message.error('Failed to copy URL');
      }
    );
  };

  React.useEffect(() => {
    if (!token) return;

    dispatch(setLoading(true));

    getData(
      urlMap.getInterviewResult + '/' + organizationId + '/' + interviewId,
      token
    ).then((data) => {
      console.log(data);
      dispatch(setLoading(false));
      if (data.success === true) {
        setInterview(data.interview);
        dispatch(setSuccess(data.message));
      } else {
        dispatch(setError(data.message));
      }
    });
  }, [token]);

  const interviewDuration = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    const timeDifference = Math.abs(date2 - date1);

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
  };

  const handleOpenClick = (firebaseFileUrl) => {
    window.open(firebaseFileUrl, '_blank');
  };

  React.useEffect(() => {});
  return (
    interview && (
      <div style={{ padding: '20px' }}>
        <h1>Interview_Shivam</h1>
        <Card>
          <p>
            Interview URL:{' '}
            <span
              className={styles.linkText}
            >{`${window.location.origin}/interview/${interviewId}/start`}</span>
            <Button
              icon={<CopyOutlined />}
              onClick={handleCopyClick.bind(
                this,
                `${window.location.origin}/interview/${interviewId}/start`
              )}
              disabled={copySuccess}
            >
              {copySuccess ? 'Copied' : 'Copy'}
            </Button>
          </p>
          <p>
            Attempt Date:{' '}
            {interview.status === 'complete' ? interview.attemptedAt : 'NA'}
          </p>
          <p>
            Interview Duration:{' '}
            {interview.status === 'complete'
              ? interviewDuration(interview.attemptedAt, interview.endedAt)
              : 'NA'}
          </p>
          <p>
            Result:{' '}
            {interview.status === 'complete' ? interview.totalScore : 'NA'}
          </p>
        </Card>
        {interview.status === 'complete' && (
          <Collapse accordion>
            {interview.solutions.map((solution, idx) => {
              return (
                <Panel
                  header={`Question ${idx + 1}: ${solution.question}`}
                  key={idx + 1}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Button
                        type='primary'
                        className={styles.downloadButton}
                        onClick={handleOpenClick.bind(this, solution.audioUrl)}
                      >
                        Download Audio File
                      </Button>
                    </Col>
                  </Row>
                  <Card>
                    <h3>Result</h3>
                    <p>{solution.score}/10</p>
                    <h3>Remark</h3>
                    <p>{solution.remark}</p>
                  </Card>
                  <Card>
                    <h3>Transcription</h3>
                    <p>{solution.transcript}</p>
                  </Card>
                </Panel>
              );
            })}
          </Collapse>
        )}
      </div>
    )
  );
};

export default InterviewResult;
