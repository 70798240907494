import { Button } from 'antd';
import classes from './styles.module.css';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { postData } from './../../utils/axios/index';
import { urlMap } from '../../utils/urls';
import { useDispatch } from 'react-redux';
import { setError, setLoading, setSuccess } from '../../store/modalSlice';
import { setQuestions } from '../../store/questionSlice';

const HomePage = () => {
  const { interviewId } = useParams();
  const naviate = useNavigate();
  const dispatch = useDispatch();

  console.log(interviewId);

  const handleStartInterview = async () => {
    dispatch(setLoading(true));
    const data = await postData(urlMap.startInterview, {
      packetId: interviewId,
    });

    dispatch(setLoading(false));

    if (data.success === true) {
      dispatch(setSuccess(data.message));
      naviate(`/interview/${interviewId}`);
      dispatch(setQuestions(data.packet.role.questions));
    } else {
      dispatch(setError(data.message));
    }
  };

  return (
    <main className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.interviewText}>Your Interview is ready</div>
        <Button
          className={classes.button}
          size='large'
          onClick={handleStartInterview}
        >
          Start
        </Button>
      </div>
    </main>
  );
};

export default HomePage;
