import React from 'react';
import classes from './styles.module.css';
import SpeakAnimation from '../../components/SpeakAnimation';
import { useNavigate, useParams } from 'react-router-dom';
import AudioAnalyser from 'react-audio-analyser';

import { Button } from 'antd';
import { getAudioProps } from '../../utils/recorder';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../store/modalSlice';
import { postData } from './../../utils/axios/index';
import { urlMap } from '../../utils/urls';

const QuestionPage = () => {
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [status, setStatus] = React.useState('');
  const [audioSrc, setAudioSrc] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const interviewQuestions = useSelector((state) => state.questions.questions);

  const { interviewId } = useParams();

  const controlAudio = (status) => {
    setStatus(status);
  };

  const audioProps = getAudioProps(
    status,
    audioSrc,
    setAudioSrc,
    interviewQuestions[questionIndex],
    interviewId,
    questionIndex + 1 === interviewQuestions.length
  );

  const handleMoveToNextQuestion = async () => {
    controlAudio('inactive');
    setTimeout(() => {
      setQuestionIndex((prev) => prev + 1);
      controlAudio('recording');
    }, 1000);
  };

  const handleEndInterview = async () => {
    controlAudio('inactive');
    dispatch(setLoading(true));
    setTimeout(async () => {
      postData(urlMap.endInterview, { packetId: interviewId });
      navigate('/result');
      dispatch(setLoading(false));
    }, 2000);
  };

  React.useEffect(() => {
    controlAudio('recording');
    console.log('Starting recording');
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.recorder}>
        <AudioAnalyser {...audioProps} />
      </div>
      <div className={classes.questionContainer}>{`Q${questionIndex + 1}. ${
        interviewQuestions[questionIndex]
      }`}</div>
      <div className={classes.actionContainer}>
        <div className={classes.voice}>
          <SpeakAnimation />
        </div>
        {questionIndex === interviewQuestions.length - 1 ? (
          <Button className={classes.button} onClick={handleEndInterview}>
            End Interview
          </Button>
        ) : (
          <Button className={classes.button} onClick={handleMoveToNextQuestion}>
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuestionPage;
